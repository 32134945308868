import React from 'react'
import TabsHome from '../../../../src/common/components/tabsHome'
import RevisionCardMobileHome from '../../../../src/common/components/revisionCardMobileHome/revisionCardMobileHome'
import {
  FEATURE_FLAGS,
  isFeatureEnabled,
} from '../../../../src/common/utils/featureFlag'
import SearcNeumaticosMotoCardHome from '../../../../src/common/components/SearchNeumaticosMotoCardHome/SearcNeumaticosMotoCardHome'
import RevisionMotoCardMobileHome from '../../../../src/common/components/revisionMotoCardMobileHome/revisionMotoCardMobileHome'
import BateriasCardMobileHome from '../../../../src/common/components/bateriasCardMobileHome/bateriasCardMobileHome'
import SearchNeumaticos from '../../../../src/common/components/searchNeumaticosCardHome'

const HomeTabsSection = () => {
  return (
    <>
      <TabsHome />
      <SearchNeumaticos />
      <RevisionCardMobileHome />
      {isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO) && (
        <SearcNeumaticosMotoCardHome />
      )}
      {isFeatureEnabled(FEATURE_FLAGS.REVISION_MOTO) && (
        <RevisionMotoCardMobileHome />
      )}
      {isFeatureEnabled(FEATURE_FLAGS.BATERIA) && <BateriasCardMobileHome />}
    </>
  )
}

export default HomeTabsSection
